@font-face {
  font-family: fontello;
  src: url("fontello.1bb02fe5.eot");
  src: url("fontello.1bb02fe5.eot#iefix") format("embedded-opentype"), url("fontello.559f3f7f.woff2") format("woff2"), url("fontello.94386c39.woff") format("woff"), url("fontello.ce472762.ttf") format("truetype"), url("fontello.d5ef1937.svg#fontello") format("svg");
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"]:before, [class*=" icon-"]:before {
  speak: never;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
  width: 1em;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-left: .2em;
  margin-right: .2em;
  font-family: fontello;
  font-style: normal;
  font-weight: normal;
  line-height: 1em;
  display: inline-block;
}

.icon-fa-mail:before {
  content: "";
}

.icon-fal-long-arrow-right:before {
  content: "";
}

.icon-fa-times:before {
  content: "";
}

.icon-fa-twitter:before {
  content: "";
}

.icon-fa-bars:before {
  content: "";
}

.icon-fa-instagram:before {
  content: "";
}

.icon-far-long-arrow-right:before {
  content: "";
}

.icon-fa-facebook-official:before {
  content: "";
}

.icon-fa-linkedin:before {
  content: "";
}

/*# sourceMappingURL=portfolio.70c5a1a7.css.map */
